import React, {ReactNode} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Theme, ThemeProvider} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {GlobalStyles, Grid, IconButton, Link, Typography} from "@mui/material";
import {Instagram, LinkedIn} from "@mui/icons-material";
import {StaticImage} from "gatsby-plugin-image";
import {sectionSpacing} from "../../theme";
import Navbar from "./navbar";

interface Props {
    children: ReactNode
    theme: Theme
}
export default function Index({ children, theme }: Props) {
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navbar />
                <GlobalStyles
                    styles={{
                        body: { backgroundColor: "#F4F2EE" }
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}
                >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <main>
                        {children}
                    </main>
                    <Box
                        component={'footer'}
                        sx={{
                            mt: 'auto',
                        }}
                    >
                        <Container
                            maxWidth={'xl'}
                        >
                            <Grid
                                container
                                justifyContent={'space-between'}
                                alignItems={'flex-center'}
                                paddingY={sectionSpacing}
                                textAlign={'center'}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    textAlign={{xs: 'center', md: 'left'}}
                                >
                                        <StaticImage
                                          alt={'bert bimmel logo'}
                                          src={'../../images/logos/bert_bimmel.svg'}
                                          width={136.49}
                                        />
                                    <address>
                                        <Link href={'tel:0118414014'}>
                                            0118 41 40 14
                                        </Link><br />
                                        <Link href={'mailto:makelaar@bertbimmel.nl'}>
                                            makelaar@bertbimmel.nl
                                        </Link><br />
                                    </address>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <StaticImage
                                        src={'../../images/logo-zwart.png'}
                                        width={277}
                                        alt={'logo'}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    textAlign={{md: 'right'}}
                                >
                                    <Typography
                                        fontSize={12}
                                        textTransform={'uppercase'}
                                        fontFamily={'hind'}
                                        marginTop={5}
                                    >
                                        © De Realisatie {new Date().getFullYear()} | <a href="https://www.dna-makelaars.nl/privacyverklaring.pdf" rel="noopener noreferrer" target="_blank">privacy
                                        voorwaarden</a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    );
}