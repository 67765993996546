import React from 'react'
import {graphql, Link, useStaticQuery} from "gatsby";
import Toolbar from "@mui/material/Toolbar";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Button, Grid, IconButton, Menu, useTheme} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {StaticImage} from "gatsby-plugin-image";

const navbarHeight = 96;
const logoHeight = 75;

interface MenuItemProps {
    url: string
    title: string
    color?: 'secondary'
    handleClick: () => void
}

const MenuButton = ({url, title, handleClick}: MenuItemProps) => {
    return (
      <Button
        component={Link}
        to={url}
        color={'inherit'}
        onClick={handleClick}
        sx={{ my: 2, display: 'block' }}
      >
          {title}
      </Button>
    )
}

const MenuLink = ({url, title, handleClick}: MenuItemProps) => {
    return (
      <MenuItem
        component={Link}
        to={url}
        key={title}
        color={'dark'}
        onClick={handleClick}
      >
          <Typography textAlign="center">{title}</Typography>
      </MenuItem>
    )
}

const Header = () => {
    const data = useStaticQuery(graphql`
        query Brochure {
            media(titel: {eq: "Brochure"}) {
                localFile {
                    publicURL
                }
            }
        }
    `)

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
      <>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
                backgroundColor: 'common.white',
                paddingY: 2
            }}
          >
              <Container maxWidth={'xl'}>
                  <Toolbar
                    disableGutters
                    component="nav"
                    variant="regular"
                  >
                      <Grid container columnSpacing={4}>
                          <Grid item xs={2} md={5} >
                              <Box display={{ xs: 'none', md: 'flex'}} justifyContent={'flex-end'} flexDirection={'row'}>
                                  <MenuButton url={'/'} title={'Home'} handleClick={handleCloseNavMenu} />
                                  <MenuButton url={'/locatie'} title={'Locatie'} handleClick={handleCloseNavMenu} />
                                  <MenuButton url={'/situatie'} title={'Situatie'} handleClick={handleCloseNavMenu} />
                                  <MenuButton url={'/woningen'} title={'Woningen'} handleClick={handleCloseNavMenu} />
                                  <MenuButton url={'/beschikbaar'} title={'Beschikbaar'} handleClick={handleCloseNavMenu} />
                              </Box>
                          </Grid>
                          <Grid item xs={8} md={2} textAlign={'center'}>
                              <Link to={'/'}>
                                  {desktop ?
                                    <StaticImage
                                      alt={'logo'}
                                      src={'../../images/logo-zwart.png'}
                                      height={logoHeight}
                                      style={{
                                          zIndex: 1
                                      }}
                                    />
                                    :
                                    <StaticImage
                                      alt={'logo'}
                                      src={'../../images/logo-zwart.png'}
                                      height={logoHeight}
                                      style={{
                                          zIndex: 1
                                      }}
                                    />
                                  }
                              </Link>
                          </Grid>
                          <Grid item xs={2} md={5}>
                              <Box display={{ xs: 'none', md: 'flex'}} flexDirection={'row'} justifyContent={'flex-start'}>
                                  <MenuButton url={'/extra'} title={'Extra'} handleClick={handleCloseNavMenu} color={'secondary'} />
                                  <MenuButton url={'/financieel-advies'} title={'Financieel advies'} handleClick={handleCloseNavMenu} color={'secondary'} />
                                  <Button
                                    href={data.media.localFile.publicURL}
                                    color={'secondary'}
                                    target={'_blank'}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, display: 'block' }}
                                  >
                                      Brochure
                                  </Button>
                                  <MenuButton url={'/downloads'} title={'Downloads'} handleClick={handleCloseNavMenu} color={'secondary'} />
                                  <MenuButton url={'/contact'} title={'Contact'} handleClick={handleCloseNavMenu} color={'secondary'} />
                              </Box>
                              <Box
                                sx={{
                                    display: { xs: 'flex', md: 'none' }
                                }}
                                justifyContent={'flex-end'}
                              >
                                  <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                  >
                                      <MenuIcon />
                                  </IconButton>
                                  <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                  >
                                      <MenuLink url={'/'} title={'Home'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/locatie'} title={'Locatie'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/situatie'} title={'Situatie'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/woningen'} title={'Woningen'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/beschikbaar'} title={'Beschikbaar'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/extra'} title={'Extra'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/financieel-advies'} title={'Financieel advies'} handleClick={handleCloseNavMenu} />
                                      <MenuItem
                                        href={data.media.localFile.publicURL}
                                        onClick={handleCloseNavMenu}
                                      >
                                          Brochure
                                      </MenuItem>
                                      <MenuLink url={'/downloads'} title={'Downloads'} handleClick={handleCloseNavMenu} />
                                      <MenuLink url={'/contact'} title={'Contact'} handleClick={handleCloseNavMenu} />
                                  </Menu>
                              </Box>
                          </Grid>
                      </Grid>
                  </Toolbar>
              </Container>
          </AppBar>
      </>
    )
}

export default Header
