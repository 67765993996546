import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const sectionSpacing = 10
export const textPadding = 8
export const sectionPadding = 5;
export const sectionGridSpacing = 5;
export const headerMargin = 10;
export const textMaxWidth = 768;

const theme = createTheme({
    palette: {
        primary: {
            main: '#CDA559',
            dark: '#CDA559',
            contrastText: 'white',
        },
        secondary: {
            main: '#CDA559'
        },
        info: {
            main: '#fff',
            contrastText: 'black',
        },
        warning: {
            main: '#FFBA00'
        },
        background: {
            paper: '#F4F2EE'
        },
        divider: '#CDA559'
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined'
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderColor: '#CDA559'
                }
            },
        }
    },
    typography: {
        fontFamily: [
            'Lato',
            'Arial'
        ].join(','),
        h2: {
            fontFamily: 'Anton',
            fontSize: '2.813rem',
            marginBottom: 24,
        },
        h3: {
            fontFamily: 'Anton',
            fontSize: '2rem',
            color: '#B49656'
        },
        button: {
            fontFamily: 'Anton',
        }
    },

});

export default responsiveFontSizes(theme);
